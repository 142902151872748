<template>
  <div class="card">
    <div class="bg-blue-light px-1 rounded pb-2">
      <TitleButton
          class="mt-1"
          btnTitle="List"
          :showBtn="true"
          :showAddNew="false"
          :title="headerTitle"
          @onClickCloseButton="navigateToListPage"
      />

      <div class="row mt-2 gy-1">
        <div class="row mt-1">
          <div class="col-sm-4">
            <label class="align-middle">Supplier</label>
            <AsyncSelect
              placeholder="Select Supplier"
              v-model="contactProfile"
              :api-service="fetchContactProfiles"
              :additional-query="additionalContactOption"
              label="full_name"
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Account Head</label>
            <v-select
                placeholder="Account Head"
                v-model="formData.account_head_id"
                :options="accountPayable"
                label="name"
                :reduce="name => name.id"
            />
          </div>
          <div class="col-sm-4">
            <label class="align-middle">Warehouse</label>
            <v-select
                placeholder="Select Warehouse"
                v-model="formData.business_location_id"
                :options="locations"
                label="text"
                :reduce="text => text.id"
            />
          </div>
          <div class="col-sm-4 mt-2">
            <label class="align-middle">GRN No</label>
            <input type="text" class="form-control" :value="formData.grn_number" readonly>
          </div>
          <div class="col-sm-4 mt-2">
            <label class="align-middle">GRN Date</label>
            <input type="date" class="form-control" v-model="formData.grn_date">
          </div>
        </div>
      </div>
    </div>

    <div class="px-2 mt-1">
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-bs-toggle="tab"  aria-controls="home" role="tab" aria-selected="true">Item Details</a>
        </li>
      </ul>
    </div>

    <div class="px-2 mt-1">
      <AddItemDetails
          class="mt-2"
          v-for="(data, index) in itemsDetails"
          :productTypes="productTypes"
          :key="index"
          :index="index"
          :data="data"
          type="normal"
          @onClose="removeItem"
      />
    </div>

    <div class="row me-1 mt-3 px-2">
      <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
      </div>
    </div>

    <div class="p-1">
      <div class="row mt-3 px-2">
        <div class="col-12">
          <div class="mb-2">
            <label for="note" class="form-label fw-bold">Memo</label>
            <textarea v-model="formData.description" placeholder="Memo" class="form-control" rows="2" id="memo"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
      <hr class="hr-full-width mb-1">
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveGRN()"
              class="btn btn-primary">
        Save
      </button>
      <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="saveGRN()"
              class="btn btn-primary">Save & New
      </button>
      <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
    </div>

    <GlobalLoader/>
  </div>
</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from "vue-router";
import TitleButton from '@/components/atom/TitleButton'
import AddItemDetails from "@/components/molecule/procurement/inventory/grn/AddItemDetails";
import AddButton from "@/components/atom/AddButton";
import handleBusinessesLocations from "@/services/modules/businessesLocations";
import handleContact from "@/services/modules/contact";
import handleInventory from "@/services/modules/inventory";
import handlePurchaseAndSales from "@/services/modules/purchase";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";

const {fetchAccountHead, ...rest} = handlePurchaseAndSales()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const {
  fetchHome,
  fetchGrnNumber,
  createNewGRN,
  updateGRN,
  fetchSingleGRN,
} = handleInventory()

const router = useRouter()
const route = useRoute()
const showError = inject('showError');
const showSuccess = inject('showSuccess')
const companyId = computed(() => {
  return route.params.companyId
})

let loader =ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let isCreate = ref(true)
let headerTitle = ref("New GRN")
let contacts = ref([])
let locations = ref([])
let accountPayable = ref([])
let grnEditData = ref({})
const productTypes = ref([]);
const contactProfile = ref(null);
const additionalContactOption = ref({
  roles: 'supplier'
});
let grnData = {
  general: [],
}
let formData = ref({
  company_id: companyId,
  contact_profile_id: null,
  business_location_id: null,
  account_head_id: null,
  grn_number: null,
  grn_date: null,
  status: 'pending',
  description: '',
  item_details: [],
})

const itemsDetails = computed(() => {
  return formData.value.item_details
})

function navigateToListPage() {
  delete route.query.grnId;
  router.push({name: `grn-list`, params: route.params, query: route.query});
}

function removeItem(index) {
  formData.value.item_details.splice(index, 1)
}

function saveGRN() {
  if (formData.value.contact_profile_id === null){
    showError("Please select a supplier");
    return;
  }

  if (isCreate.value) {
    handleGRNSubmit();
  } else {
    handleUpdateGRN();
  }
}

function handleGRNSubmit() {
  grnData.company_id  = formData.value.company_id;
  grnData.contact_profile_id = formData.value.contact_profile_id;
  grnData.business_location_id = formData.value.business_location_id;
  grnData.account_head_id = formData.value.account_head_id;
  grnData.grn_number = formData.value.grn_number;
  grnData.grn_date = formData.value.grn_date;
  grnData.grn_type = "normal";
  grnData.status = "pending";
  grnData.description = formData.value.description;
  grnData.has_item_detail = true;

  grnData.item_details = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data = {};
      data.product_id = item.product_id;
      data.quantity = item.quantity;
      data.rate = item.rate;
      data.vat = 0;
      data.description = item.description;
      grnData.item_details.push(data);
    })
  }

  createGRN();
}

function handleUpdateGRN() {
  grnData.company_id  = formData.value.company_id;
  grnData.contact_profile_id = formData.value.contact_profile_id;
  grnData.business_location_id = formData.value.business_location_id;
  grnData.account_head_id = formData.value.account_head_id;
  grnData.grn_date = formData.value.grn_date;
  grnData.grn_number = formData.value.grn_number;
  grnData.description = formData.value.description;
  grnData.status = formData.value.status;
  grnData.has_item_detail = true;

  grnData.item_details = [];
  if (formData.value.item_details.length > 0) {
    formData.value.item_details.forEach((item) => {
      let data = {};
      data.id = item.id;
      data.grn_master_id = formData.value.id;
      data.product_id = item.product_id;
      data.quantity = item.quantity;
      data.rate = item.rate;
      data.vat = 0;
      data.description = item.description;
      grnData.item_details.push(data);
    })
  }

  updateGRNData();
}

function createGRN(redirect = true) {
  createNewGRN(grnData, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message)
          if (redirect) navigateToListPage()
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false
        console.log(err)
      })
}

function updateGRNData() {
  updateGRN(grnData, route.query.grnId, getQuery())
      .then(res => {
        loader.value = false
        if (res.status) {
          showSuccess(res.message);
          onPageReload();
        }
        if (!res.status) {
          showError(res.message)
        }
      })
      .catch(err => {
        loader.value = false;
        console.log(err)
      })
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function setFormData() {
  if (isCreate.value) {
    formData.value.grn_date = new Date().toISOString().split('T')[0];
    return;
  }

  headerTitle.value = "Update GRN"
  formData.value.grn_date = new Date(grnEditData.value.grn_date).toISOString().split('T')[0];
  formData.value.id = grnEditData.value.id;
  formData.value.grn_number = grnEditData.value.grn_number;
  formData.value.contact_profile_id = grnEditData.value.contact_profile_id;
  formData.value.business_location_id = grnEditData.value.business_location_id;
  formData.value.account_head_id = grnEditData.value.account_head_id;
  formData.value.description = grnEditData.value.description;
  formData.value.status = grnEditData.value.status;
  contactProfile.value = grnEditData.value.contact_profile
  formData.value.item_details = [];
  
  grnEditData.value.grn_general.forEach((item) => {
    formData.value.item_details.push(item);
  })
}

function onClickAdd() {
  formData.value.item_details.push({
    product_id: null,
    quantity: '',
    description: ''
  })
}

function onPageReload() {
  loader.value=true
  const accountHeadRes = fetchAccountHead(getQuery())
  const businessLocationRes = fetchBusinessLocationsList(getQuery())
  const payableRes = rest.fetchAccountPayable(getQuery())
  const contactRes = fetchContactProfiles(getQuery())

  if(route.query.grnId && route.query.grnId !== '' && route.query.grnId !== undefined){
    isCreate.value = false;
  }

  Promise.all([
    fetchHome().then(res => {
      if (res.data && res.data.product_type) {
        for (let key in res.data.product_type) {
          productTypes.value.push({
            id : key,
            text : res.data.product_type[key]
          });
        }
      }
    }),
    accountHeadRes.then(res=> {
      if(res.data) accountHeads.value = res.data
    }),
    businessLocationRes.then(res=> {
      if(res.data) locations.value = res.data
    }),
    payableRes.then(res=> {
      if(res.data) {
        accountPayable.value = res.data
      }
      if(res.data?.length) {
        formData.value.account_head_id = res.data[0].id
      }
    }),
    contactRes.then(res=> {
      if(res.data) contacts.value = res.data;

      if (isCreate.value){
        const grnNumberRes = fetchGrnNumber(getQuery());
        grnNumberRes.then(res => {
          formData.value.grn_number = res;
          formData.value.item_details = [
            {
              product_id: null,
              quantity: '',
              rate: 0,
              amount: '',
              description: ""
            }
          ]
          setFormData();
        })
        return;
      }

      const fetchGNR = fetchSingleGRN(route.query.grnId, getQuery());
      fetchGNR.then(res2 => {
        grnEditData.value = res2.data;
        setFormData();
      });
    }),
  ])
  .then(() => {
    loader.value=false;
  })
  .catch((err)=>{loader.value=false})
}

onMounted(async () => {
  onPageReload();
})
</script>